import React from "react";


const Error404 = () => {
  return (
    <div>
     Error Page
    </div>
  );
};

export default Error404;
